export const justifyBuildingLevelVisuals = [
  {
    name: "Demo",
    markdown: `![Justify building level GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const justifyBuildingLevelArchwayVisuals = [
  {
    name: "Demo",
    markdown: `![Justify building level archway GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
  {
    name: "Reference image",
    markdown: `![Justify building level archway reference](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
];

export const justifyBuildingLevelSplitVisuals = [
  {
    name: "Demo",
    markdown: `![Justify building level split GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
  {
    name: "Reference image",
    markdown: `![Justify building level split reference](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
];

export const justifyBuildingLevels = {
  order: 31,
  name: "Horizontally align building levels",
  keywords: "floors stories story vertical left center right arch",
  subcategory: "Properties",
  markdown: `# Horizontally align building levels

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  In Tower Hunt, a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %} can display {% inlineRouterLink articleId="add-a-land-covering" %}buildings{% /inlineRouterLink %} that have {% inlineRouterLink articleId="add-building-levels" %}vertical levels{% /inlineRouterLink %}. Once those levels have {% inlineRouterLink articleId="add-a-floor-area" %}floor areas{% /inlineRouterLink %} and {% inlineRouterLink %}area measures{% /inlineRouterLink %}, floors will display at different widths. When you want a Tower Hunt building to closely resemble an actual building, sometimes it is necessary to display certain levels of the building at a particular horizontal alignment.

  {% callout type="caution" %}
  **Important:** In order to see the relative width of each building level, **every** floor area must have a {% inlineRouterLink articleId="add-floor-area-measures" %}standardized area{% /inlineRouterLink %}.
  {% /callout %}

  ## What to expect

  When you set the alignment for a building level, it applies only to the selected level. Higher levels may shift horizontally in order to stay visually connected to the selected level, but each level has its own horizontal alignment. This gives you the freedom to express a wide variety of designs.

  ## Steps

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="add-building-levels" %}building level{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="horizontalAlign" /%}.
  4. In the pop-up that appears above the button, select **L** for left, **C** for center, or **R** for right alignment.
  5. In the pop-up that appears above the button, click **Save**. The building level now aligns to the selected side.
  {% /tablessContainer %}

  ## Examples

  {% tabbedContainer tabs=$justifyBuildingLevelVisuals /%}

  ### Left

  ![Left justified example screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ### Center

  ![Center justified example screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ### Right

  ![Right justified example screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ### Mixed

  ![Mixed alignment example screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ## Archways and split peaks

  A more advanced situation is when there is open space between two parts of the same (real-life) building level. At the bottom of a building, this can look like an archway. At the top of a building, it can look like individual peaks/penthouses. To approximate these types of designs, you can use horizontal alignment in combination with {% inlineRouterLink articleId="group-land-coverings" %}land covering groupings{% /inlineRouterLink %}. The result is that a single real-life building is split in half horizontally and displayed in the diagram as two grouped buildings.

  {% tablessContainer %}
  1. Create two {% inlineRouterLink articleId="add-a-land-covering" %}buildings{% /inlineRouterLink %} side-by-side on the same {% inlineRouterLink articleId="add-a-fee-parcel" %}fee parcel{% /inlineRouterLink %}.
  2. {% inlineRouterLink articleId="group-land-coverings" %}Group the land coverings{% /inlineRouterLink %}.
  3. When {% inlineRouterLink articleId="add-a-floor-area" %}adding floor areas{% /inlineRouterLink %}, think of the two grouped buildings as one single building. For example, when setting up the first floor you should spread the existing floor areas across the two grouped buildings.
  4. Where the split should appear, {% inlineRouterLink articleId="horizontally-align-building-levels" sectionName="Steps" %}left-align the lefthand building levels{% /inlineRouterLink %} and {% inlineRouterLink articleId="horizontally-align-building-levels" sectionName="Steps" %}right-align the righthand building levels{% /inlineRouterLink %}.
  {% /tablessContainer %}

  ### Archway

  {% tabbedContainer tabs=$justifyBuildingLevelArchwayVisuals /%}

  ### Split peaks

  {% tabbedContainer tabs=$justifyBuildingLevelSplitVisuals /%}`,
};
